import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/layout/TopBar'
import AcProduct from 'components/layout/AcProduct'
import TemplateSectionBelow from 'components/layout/TemplateSectionBelow'
import TemplateSectionAbove from 'components/layout/TemplateSectionAbove'
import Footer from 'components/layout/Footer'
import { useTranslation, withTranslation } from 'react-i18next'

const mapStateToProps = ({ settings }) => ({ settings })

@withRouter
@connect(mapStateToProps)
class AppLayout extends React.PureComponent {
  render() {
    const {
      children,
      settings: {
        isContentNoMaxWidth,
        isAppMaxWidth,
        isGrayBackground,
        isSquaredBorders,
        isCardShadow,
        isBorderless,
        isTopbarFixed,
        isGrayTopbar,
        selectedTab,
      },
      i18n,
    } = this.props
    const isCloud = selectedTab === 'cloud'
    localStorage.setItem('sharedids', '[]')
    localStorage.setItem('login', '0')

    return (
      <Layout
        className={classNames({
          air__layout__contentNoMaxWidth: isContentNoMaxWidth,
          air__layout__appMaxWidth: isAppMaxWidth,
          air__layout__grayBackground: isGrayBackground,
          air__layout__squaredBorders: isSquaredBorders,
          air__layout__cardsShadow: isCardShadow,
          air__layout__borderless: isBorderless,
        })}
      >
        <AcProduct lang={i18n.language} />

        {/* <MenuLeft /> */}
        <Layout>
          <Layout.Header
            className={classNames('air__layout__header', {
              air__layout__fixedHeader: isTopbarFixed,
              air__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          <div
            id="div-gpt-ad-1604031951387-0"
            style={{
              textAlign: 'center',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <script
              dangerouslySetInnerHTML={{
                __html: `
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1604031951387-0'); });
              `,
              }}
            />
          </div>
          <Layout.Content style={{ minHeight: '500px', position: 'relative', width: '100%' }}>
            <div
              style={{
                borderBottom: '2px solid #1b55e3',
                marginTop: 49,
                width: '100%',
                position: 'absolute',
              }}
            >
              {' '}
            </div>
            <div
              className="container-fluid py-3 mr-auto ml-auto"
              style={{ padding: '0', width: '75vw', maxWidth: '1400px' }}
            >
              {children}
            </div>
          </Layout.Content>
          {!isCloud && (
            <>
              <TemplateSectionAbove />
              <TemplateSectionBelow />
            </>
          )}

          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    )
  }
}

export default withTranslation()(AppLayout)
